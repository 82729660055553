import React from 'react'
import {
  Card,
  CardsContainer,
  Content,
  CustomGrid,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './QueriesNarrative.styles'
import { Text, Title } from '../../../../youtalk-storybook/src/ui'
import { cardData } from './cardData'

export const QueriesNarrative = () => (
  <Wrapper>
    <section>
      <CustomGrid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <Title.H2>
                Запросы, с которыми работают по нарративному подходу
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Нарративный подход эффективен при работе с различными запросами,
                включая:
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>

          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <TextWrapper>
                  <Text.Large semiBold>{card.text}</Text.Large>
                </TextWrapper>
              </Card>
            ))}
          </CardsContainer>
        </Content>
      </CustomGrid>
    </section>
  </Wrapper>
)
