import messagesIcon from '../../../../static/img/NarrativnayaPsihoterapiya/messages-icon.svg'
import styled from 'styled-components'
import { Text } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background: #dfebf9;
`
export const Content = styled.div`
  padding: 124px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    padding: 99px 82px;
  }

  @media (max-width: ${size.sm}) {
    padding: 83px 32px;
  }

  @media (max-width: ${size.xs}) {
    padding: 73px 16px;
  }
`

export const MessagesImage = styled.img.attrs(() => ({
  src: messagesIcon
}))`
  text-align: center;
  position: absolute;
  top: -51px;
  left: 50%;
  transform: translateX(-50%);
`

export const CardContainer = styled.div`
  width: 792px;
  padding: 40px 24px;
  border-radius: 24px;
  border: 1px solid #dedede;
  background: white;
  margin: 0 auto;
  position: relative;
  text-align: center;

  @media (max-width: ${size.md}) {
    width: 604px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }

  ${Text.Large} {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
