import React from 'react'
import {
  CardEightImage,
  CardFiveImage,
  CardFourImage,
  CardOneImage,
  CardSevenImage,
  CardSixImage,
  CardThreeImage,
  CardTwoImage
} from './QueriesNarrative.styles'

export const cardData = [
  {
    text: 'Кризисы и ситуации перемен',
    image: <CardOneImage />
  },
  {
    text: 'Процессы горевания',
    image: <CardTwoImage />
  },
  {
    text: 'Возвращение авторства и чувства контроля в жизни',
    image: <CardThreeImage />
  },
  {
    text: 'Чувство личной несостоятельности',
    image: <CardFourImage />
  },
  {
    text: 'Желание поменять привычные паттерны поведения',
    image: <CardFiveImage />
  },
  {
    text: 'Поиск опор и смысла в жизни',
    image: <CardSixImage />
  },
  {
    text: 'Травмы и опыт насилия',
    image: <CardSevenImage />
  },
  {
    text: 'Необходимость в поддержке и ресурсах',
    image: <CardEightImage />
  }
]
