import cardEightIcon from '../../../../static/img/NarrativnayaPsihoterapiya/queriesNarrative-image-8.svg'
import cardFiveIcon from '../../../../static/img/NarrativnayaPsihoterapiya/queriesNarrative-image-5.svg'
import cardFourIcon from '../../../../static/img/NarrativnayaPsihoterapiya/queriesNarrative-image-4.svg'
import cardOneIcon from '../../../../static/img/NarrativnayaPsihoterapiya/queriesNarrative-image.svg'
import cardSevenIcon from '../../../../static/img/NarrativnayaPsihoterapiya/queriesNarrative-image-7.svg'
import cardSixIcon from '../../../../static/img/NarrativnayaPsihoterapiya/queriesNarrative-image-6.svg'
import cardThreeIcon from '../../../../static/img/NarrativnayaPsihoterapiya/queriesNarrative-image-3.svg'
import cardTwoIcon from '../../../../static/img/NarrativnayaPsihoterapiya/queriesNarrative-image-2.svg'
import styled from 'styled-components'
import { Grid, Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const CustomGrid = styled(Grid)`
  padding-left: 10px;
  padding-right: 10px;
`

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 36px;

  @media (max-width: ${size.lg}) {
    max-width: 600px;
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
    margin-bottom: 16px;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;

  @media (max-width: ${size.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${size.xs}) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
`

export const TextWrapper = styled.div``

export const CardOneImage = styled.img.attrs(() => ({
  src: cardOneIcon
}))``

export const CardTwoImage = styled.img.attrs(() => ({
  src: cardTwoIcon
}))``

export const CardThreeImage = styled.img.attrs(() => ({
  src: cardThreeIcon
}))``

export const CardFourImage = styled.img.attrs(() => ({
  src: cardFourIcon
}))``

export const CardFiveImage = styled.img.attrs(() => ({
  src: cardFiveIcon
}))``

export const CardSixImage = styled.img.attrs(() => ({
  src: cardSixIcon
}))``

export const CardSevenImage = styled.img.attrs(() => ({
  src: cardSevenIcon
}))``

export const CardEightImage = styled.img.attrs(() => ({
  src: cardEightIcon
}))``
