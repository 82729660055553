import React from 'react'
import heartIcon from '../../../../static/img/NarrativnayaPsihoterapiya/heart.svg'
import lockIcon from '../../../../static/img/NarrativnayaPsihoterapiya/lock.svg'
import messageIcon from '../../../../static/img/NarrativnayaPsihoterapiya/message.svg'
import studyIcon from '../../../../static/img/NarrativnayaPsihoterapiya/study.svg'
import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './NarrativeInYoutalk.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const NarrativeInYoutalk = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2>Нарративная терапия онлайн в YouTalk</Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold>
                Нарративная терапия в YouTalk — это...
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={heartIcon} />
              <Text.Large semiBold>
                Быстрый подбор специалиста. Заполнение анкеты займёт несколько
                минут
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={studyIcon} />
              <Text.Large semiBold>
                Качество. Лишь 4% кандидатов становятся психологами нашего
                сервиса. Мы тщательно проверяем уровень профессионализма и
                этичности каждого
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={messageIcon} />
              <Text.Large semiBold>
                Удобство. Можно заниматься по видео, аудио и переписке. Тогда и
                там, где вам удобно
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={lockIcon} />
              <Text.Large semiBold>
                Анонимность. При желании вы можете представиться вымышленным
                именем вместо настоящего.
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
