import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  Content,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './WhoWillSuiteNarrative.styles'
import { DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'

export const WhoWillSuiteNarrative = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>Кому подойдет нарративная терапия</Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Нарративная психотерапия подходит для всех, кто хочет переписать
                историю своей жизни, осознать и изменить негативные установки и
                шаблоны мышления. Это подход для тех, кто хочет вернуть себе
                авторство своей истории, почувствовать контроль и посмотреть на
                вещи под новым углом.
              </Text.Large>
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Кому подойдет нарративная терапия" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
