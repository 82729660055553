import React from 'react'
import {
  CardContainer,
  Content,
  MessagesImage,
  Wrapper
} from './NarrativePractice.styles'
import { Text } from '../../../../youtalk-storybook/src/ui/atoms/Text'

export const NarrativePractice = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <MessagesImage />
          <Text.Large semibold>
            Нарративная практика помогает отделить человека от его проблемы,
            укрепить важные смыслы и ценности, восстановить контакт с собой и
            окружающими, а также найти опоры, которые будут поддерживать. Меняя
            то, как мы о себе думаем, мы меняем свое поведение и жизнь.
          </Text.Large>
          <Text.Large bold>
            Мы сами являемся авторами своей жизни, и от нас зависит, какой она
            будет.
          </Text.Large>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
