import React from 'react'
import horseIcon from '../../../../static/img/NarrativnayaPsihoterapiya/horse-icon.svg'
import smileIcon from '../../../../static/img/NarrativnayaPsihoterapiya/smile-icon.svg'
import starIcon from '../../../../static/img/NarrativnayaPsihoterapiya/star-icon.svg'
import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './WhatIsNarrative.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const WhatIsNarrative = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Что такое нарративная психотерапия и как она работает
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Нарративная психотерапия фокусируется на пересочинении историй,
                которые мы рассказываем о себе. Вместо того, чтобы рассматривать
                себя как жертву обстоятельств, нарративная терапия помогает вам
                увидеть себя как главного героя своей истории, способного на
                перемены и рост.
              </Text.Large>
              <Text.Large semiBold>
                Нарративный подход позволяет нам увидеть...
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={smileIcon} />
              <Text.Large semiBold>Cвои ценности</Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={horseIcon} />
              <Text.Large semiBold>
                Какими навыками мы обладаем, чтобы справиться с ситуацией
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={starIcon} />
              <Text.Large semiBold>
                Что поддерживает нас в трудные времена
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
